import { RELEASE_DATE_FOR_RECRUITMENT, MAMA_RELEASE_DATE } from './constants';
import { imageUrls } from './imageUrls';
import { updateVideoPlayerPosition } from './youtubePlayer';
import { initAnchors } from './anchors';
import { initFAQ } from './faq';
import { createDepthString, getCommonGTMParams, sendGTMEvent } from './gtm';

export function updateImage(languageCode) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const currentDate = new Date();

    if (currentDate >= MAMA_RELEASE_DATE) {
        // Check if mama wrapper already exists
        const existingMamaWrapper = document.querySelector(
            '.mama-image-wrapper'
        );
        if (existingMamaWrapper) {
            // If exists, update image and button styles
            const image = existingMamaWrapper.querySelector('img');
            const button = existingMamaWrapper.querySelector('.mama-button');

            image.src = imageUrls.mama[languageCode] || imageUrls.mama['en'];
            button.href = `https://boysplanetapply.com/?locale=${languageCode}`;

            button.className = `mama-button ${languageCode}`;
            return;
        }

        // If not exists, create new wrapper and content
        const newWrapper = document.createElement('div');
        newWrapper.className = 'mama-image-wrapper';

        const mainImage = document.createElement('img');
        mainImage.src = imageUrls.mama[languageCode] || imageUrls.mama['en'];

        const button = document.createElement('a');
        button.href = `https://boysplanetapply.com/?locale=${languageCode}`;
        button.target = '_blank';
        button.className = `mama-button ${languageCode}`;

        newWrapper.appendChild(mainImage);
        newWrapper.appendChild(button);
        document.querySelector('.container').appendChild(newWrapper);

        // Hide old elements
        document.querySelector('.image-wrapper').style.display = 'none';
        document.querySelector('.video-button-overlay').style.display = 'none';
        document.querySelector('.grid-container').style.display = 'none';
        document.querySelector('.faq-container').style.display = 'none';
    } else {
        const hasReleaseDatePassed =
            currentDate >= RELEASE_DATE_FOR_RECRUITMENT;

        const mainImage = document.querySelector('.image-wrapper > img');
        if (mainImage) {
            const commonParams = getCommonGTMParams(hasReleaseDatePassed);
            const depthString = createDepthString(commonParams);

            sendGTMEvent('screen_view', {
                ...commonParams,
                screen_name: depthString,
            });

            const isMobile = window.innerWidth <= 450;
            const imageSet = hasReleaseDatePassed
                ? imageUrls.recruiting
                : isMobile
                  ? imageUrls.mobile
                  : imageUrls.desktop;
            mainImage.src = imageSet[languageCode] || imageSet['en'];

            if (hasReleaseDatePassed) {
                initAnchors(languageCode);
                initFAQ(languageCode);
            } else {
                ['grid-container', 'faq-container'].forEach((className) => {
                    const element = document.querySelector(`.${className}`);
                    if (element) element.style.display = 'none';
                });
            }

            updateVideoPlayerPosition(languageCode, hasReleaseDatePassed);
        }
    }
}
