const localization = {
    ko: {
        title: 'PLANET B | 공식 홈페이지 | Mnet',

        description:
            'Mnet ‘Boys Planet, Girls Planet, I-LAND 시즌1 & 시즌2’ 제작진의 초대형 보이 그룹 오디션 프로젝트 <PLANET B>',
        ogTitle: 'PLANET B | 공식 홈페이지 | Mnet',
        ogDescription: `Mnet ‘Boys Planet, Girls Planet, I-LAND 시즌1 & 시즌2’ 제작진의 초대형 보이 그룹 오디션 프로젝트 <PLANET B>`,
        keywords:
            '플래닛비, 플래닛 B, 플래닛B, PLANET B, Mnet 플래닛B, 엠넷 플래닛B, Mnet 오디션, Mnet 서바이벌, PLANET B 홈페이지, PLANET B 라인업, PLANET B 출연자, PLANET B 방송정보, PLANET B 홈페이지, PLANET B 공홈, PLANET B 영상, PLANET B 클립, PLANET B 투표, PLANET B 지원, 플래닛B 홈페이지, 플래닛B 라인업, 플래닛B 출연자, 플래닛B 방송정보, 플래닛B 홈페이지, 플래닛B 공홈, 플래닛B 영상, 플래닛B 클립, 플래닛B 투표, 플래닛B 지원, 보이즈플래닛, 걸스플래닛, 보이즈플래닛2, 아이랜드, 아이랜드2, BOYS PLANET, BOYS PLANET2, ILAND, I-LAND, I-LAND2, Girls Planet',
    },
    en: {
        title: 'PLANET B | Official Website | Mnet',

        description:
            "Mnet 'Boys Planet, Girls Planet, I-LAND 시즌1 & 시즌2' 제작진의 초대형 보이 그룹 오디션 프로젝트 <PLANET B>",
        ogTitle: 'PLANET B | Official Website | Mnet',
        ogDescription: `From the producers of Mnet's ‘Boys Planet, Girls Planet, and I-LAND Season 1 & Season 2’, comes a mega boy group audition project <PLANET B>`,
        keywords:
            'PLANET B, Mnet PLANET B, Mnet audition, Mnet survival, PLANET B lineup, PLANET B cast, PLANET B broadcast information, PLANET B homepage, PLANET B video, PLANET B clip, PLANET B vote, BOYS PLANET, BOYS PLANET2, ILAND, I-LAND, I-LAND2, Girls Planet',
    },
};

// Function to get URL parameters
function getQueryParam(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

// Function to update meta tags based on locale
function updateMetaTags(locale) {
    const localizedContent = localization[locale] || localization['en'];

    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute('content', localizedContent.description);
    } else {
        console.error('Meta tag for description not found');
    }

    // Update Open Graph tags
    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
        ogTitle.setAttribute('content', localizedContent.ogTitle);
    } else {
        console.error('Meta tag for og:title not found');
    }

    const ogDescription = document.querySelector(
        'meta[property="og:description"]'
    );
    if (ogDescription) {
        ogDescription.setAttribute('content', localizedContent.ogDescription);
    } else {
        console.error('Meta tag for og:description not found');
    }

    const keywords = document.querySelector('meta[name="keywords"]');

    if (keywords) {
        keywords.setAttribute('content', localizedContent.keywords);
    } else {
        console.error('Meta tag for keywords not found');
    }

    // Update title tag
    const title = document.querySelector('title');

    if (title) {
        title.textContent = localizedContent.title;
    } else {
        console.error('Title tag not found');
    }
}

export { getQueryParam, updateMetaTags };
