export function updateDropdownColors() {
    const selectedLang = document.getElementById('selected-lang');
    const dropdownOptions = document.querySelectorAll('.dropdown-option');

    dropdownOptions.forEach((option) => {
        if (option.textContent === selectedLang.textContent) {
            option.style.color = 'white';
        } else {
            option.style.color = ''; // Reset to default color
        }
    });
}
