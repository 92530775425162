import { sendGTMEvent } from './main';

let player;

function loadYouTubeAPI() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function onYouTubeIframeAPIReady() {
    player = new YT.Player('yt-player', {
        videoId: '6TFYhR3yCKk', // YT video ID
        playerVars: {
            playsinline: 1,
        },
        events: {
            onReady: onPlayerReady,
        },
    });
}

function onPlayerReady() {
    document
        .getElementById('yt-play-button')
        .addEventListener('click', function () {
            document.getElementById('yt-player').style.display = 'block';
            player.playVideo();
        });
}

export function initVideo() {
    loadYouTubeAPI();
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
}

export function updateVideoPlayerPosition(languageCode, hasReleaseDatePassed) {
    const videoOverlay = document.getElementById('video-button-overlay');

    if (videoOverlay) {
        const classList = videoOverlay.classList;

        if (classList.length > 1) {
            classList.remove(classList[classList.length - 1]);
        }

        classList.add(
            hasReleaseDatePassed ? `recruit-${languageCode}` : languageCode
        );
    }
}
