import { initVideo } from './youtubePlayer';
import { validateUrl, initLanguage } from './language';
import { getQueryParam, updateMetaTags } from './localization';
import { initStickyHeader } from './header';
import { addBackButton, hideBackButton } from './backButton';
import { getCommonGTMParams, sendGTMEvent } from './gtm';
import { RELEASE_DATE_FOR_RECRUITMENT } from './constants';

function initApp() {
    const locale = getQueryParam('locale') || 'en';
    const isInMnetPlusApp = checkMnetPlusApp();

    validateUrl();
    updateMetaTags(locale);
    initVideo();
    initLanguage();
    initStickyHeader();
    handleBackButton(isInMnetPlusApp);

    // Add click event listeners
    addClickEventListeners();
    disablePinchZoom();
}

function checkMnetPlusApp() {
    console.log('version up');

    return (
        typeof window.MnetPlusApp !== 'undefined' &&
        window.MnetPlusApp.startsWith('Mnet Plus/')
    );
}

function handleBackButton(isInMnetPlusApp) {
    if (isInMnetPlusApp) {
        addBackButton();
    } else {
        hideBackButton();
    }
}

function disablePinchZoom() {
    document.addEventListener(
        'touchstart',
        function (e) {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );

    document.addEventListener(
        'touchmove',
        function (e) {
            if (e.scale && e.scale !== 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
}

function addClickEventListeners() {
    const currentDate = new Date();
    const hasReleaseDatePassed = currentDate >= RELEASE_DATE_FOR_RECRUITMENT;
    const commonParams = getCommonGTMParams(hasReleaseDatePassed);

    const mnetPlusLogo = document.querySelector('.header-left a');
    if (mnetPlusLogo) {
        mnetPlusLogo.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation(); // Stop the event from bubbling up

            sendGTMEvent('click_event', {
                ...commonParams,
                ep_button_location: hasReleaseDatePassed
                    ? 'PlanetB Apply'
                    : 'PlanetB Teaser',
                ep_button_range: 'GNB',
                ep_button_name: 'Logo',
            });

            setTimeout(() => {
                window.open('https://mnetplus.world/main', '_blank');
            }, 300);
        });
    }

    // Language dropdown
    document.querySelectorAll('.dropdown-option').forEach((el) => {
        el.addEventListener('click', (e) => {
            const langCode = e.target.dataset.lang;
            sendGTMEvent('click_event', {
                ...commonParams,
                ep_button_location: hasReleaseDatePassed
                    ? 'PlanetB Apply'
                    : 'PlanetB Teaser',
                ep_button_range: 'GNB',
                ep_button_name: `Language_${langCode}`,
            });
        });
    });

    // Typeform grid items
    const links = document.querySelectorAll('a.grid-item');
    if (links) {
        // Add click event listener to each link
        links.forEach((link) => {
            link.addEventListener('click', function (event) {
                event.preventDefault(); // Prevent default link behavior

                // Get the href and language from the link
                const url = this.getAttribute('href');
                const lang = this.getAttribute('data-lang');

                sendGTMEvent('click_event', {
                    ...getCommonGTMParams(
                        new Date() >= RELEASE_DATE_FOR_RECRUITMENT
                    ),
                    ep_button_location: 'Home',
                    ep_button_range: 'Apply Icon',
                    ep_button_name: `Apply_${lang}`,
                });

                // Open the URL in a new tab
                window.open(url, '_blank');
            });
        });
    }

    // Video play button
    const playButton = document.getElementById('yt-play-button');
    if (playButton) {
        playButton.addEventListener('click', () => {
            sendGTMEvent('click_event', {
                ...commonParams,
                ep_button_location: 'Home',
                ep_button_range: 'Teaser Video Screen',
                ep_button_name: 'Play',
            });
        });
    }
}

document.addEventListener('DOMContentLoaded', initApp);

// Export the sendGTMEvent function so it can be used in other modules if needed
export { sendGTMEvent };
