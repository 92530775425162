import { ALLOWED_LANGUAGES } from './constants';
import { updateImage } from './imageHandler';
import { updateMetaTags } from './localization';
import { updateDropdownColors } from './dropdown';

export function getUserPrimaryLanguage() {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0].split('-')[0];
    }
    return (navigator.language || navigator.userLanguage).split('-')[0];
}

export function updateLanguageUrls(langCode) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('locale', langCode);
    window.history.pushState(
        {},
        '',
        `${window.location.pathname}?${urlParams.toString()}`
    );
}

export function validateUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const locale = urlParams.get('locale');
    const pathname = window.location.pathname;

    if (!urlParams.has('locale')) {
        const userLanguage = getUserPrimaryLanguage();
        const defaultLocale = ALLOWED_LANGUAGES.includes(userLanguage)
            ? userLanguage
            : 'en';
        window.location.href = `${pathname}?locale=${defaultLocale}`;
        return;
    }

    if (!ALLOWED_LANGUAGES.includes(locale)) {
        window.location.href = `${pathname}?locale=en`;
    } else {
        setDefaultLanguage(locale);
    }
}

function setDefaultLanguage(langCode) {
    const dropdown = document.getElementById('language-dropdown');
    if (!dropdown) return;

    const selectedLang = dropdown.querySelector('#selected-lang');
    const dropdownOptions = dropdown.querySelectorAll('.dropdown-option');
    let defaultOption = dropdownOptions[0];

    dropdownOptions.forEach((option) => {
        if (option.getAttribute('data-lang') === langCode) {
            defaultOption = option;
        }
    });

    if (selectedLang) {
        selectedLang.textContent = defaultOption.textContent;
    }
    updateImage(langCode);
    updateLanguageUrls(langCode);
    updateDropdownColors();
}

export function initLanguage() {
    const dropdown = document.getElementById('language-dropdown');
    if (dropdown) {
        const dropdownSelect = dropdown.querySelector('.dropdown-select');
        const dropdownOptions = dropdown.querySelectorAll('.dropdown-option');
        const selectedLang = document.getElementById('selected-lang');

        dropdownSelect.addEventListener('click', () =>
            dropdown.classList.toggle('active')
        );

        dropdownOptions.forEach((option) => {
            option.addEventListener('click', function () {
                const langCode = this.getAttribute('data-lang');
                selectedLang.textContent = this.textContent;
                dropdown.classList.remove('active', 'lang-zh-cn');
                if (langCode === 'zh-cn') {
                    dropdown.classList.add(`lang-${langCode}`);
                }
                updateImage(langCode);
                updateLanguageUrls(langCode);
                updateDropdownColors();
                updateMetaTags(langCode);
            });
        });

        document.addEventListener('click', (event) => {
            if (!dropdown.contains(event.target)) {
                dropdown.classList.remove('active');
            }
        });
    }
}
