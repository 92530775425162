export function initStickyHeader() {
    const header = document.getElementById('header');
    const headerTop = header.offsetTop;

    window.addEventListener('scroll', () => {
        const scrollTop = document.documentElement.scrollTop;
        header.classList.toggle('sticky', scrollTop > headerTop);

        if (scrollTop > headerTop) {
            header.style.backgroundColor = 'rgba(21, 24, 27, 0.4)';
        } else {
            header.style.backgroundColor = ''; // Reset to default color
        }
    });
}
