import backBtn from '../assets/utils/back-btn.svg';

export function addBackButton() {
    const backButtonContainer = document.getElementById(
        'back-button-container'
    );
    const backButton = createBackButton();
    backButtonContainer.appendChild(backButton);
}

export function hideBackButton() {
    const backButtonContainer = document.getElementById(
        'back-button-container'
    );
    if (backButtonContainer) {
        backButtonContainer.style.display = 'none';
    }
}

function createBackButton() {
    const backButton = document.createElement('img');
    backButton.src = backBtn;
    backButton.alt = 'Back';
    backButton.className = 'back-button';
    backButton.addEventListener('click', handleBackButtonClick);
    return backButton;
}

function handleBackButtonClick() {
    window.ReactNativeWebView.postMessage('{"command":"close"}');
}
