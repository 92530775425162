export function getCommonGTMParams(hasReleaseDatePassed) {
    return {
        ep_page_1depth: 'poc',
        ep_page_2depth: 'cmty',
        ep_page_3depth: 'Planet B',
        ep_page_4depth: hasReleaseDatePassed ? 'Apply' : 'Teaser',
        ep_content_community: 'Planet B',
    };
}

export function sendGTMEvent(eventName, eventParams = {}) {
    try {
        const sanitizedParams = {};
        for (const [key, value] of Object.entries(eventParams)) {
            sanitizedParams[key] =
                value === null || value === undefined ? '' : String(value);
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: eventName,
            ...sanitizedParams,
        });
    } catch (error) {
        console.error('Error sending GTM event:', error);
    }
}

export const createDepthString = ({
    ep_page_1depth,
    ep_page_2depth,
    ep_page_3depth,
    ep_page_4depth,
}) => `${ep_page_1depth}>${ep_page_2depth}>${ep_page_3depth}>${ep_page_4depth}`;
