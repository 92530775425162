import imageKO from '../assets/Planet_B_teaser_ko.webp';
import imageJA from '../assets/Planet_B_teaser_ja.webp';
import imageEN from '../assets/Planet_B_teaser_en.webp';
import imageZHCN from '../assets/Planet_B_teaser_zh-cn.webp';
import imageZHTW from '../assets/Planet_B_teaser_zh-tw.webp';

import mobileImageKO from '../assets/mobile/Planet_B_teaser_ko.webp';
import mobileImageJA from '../assets/mobile/Planet_B_teaser_ja.webp';
import mobileImageEN from '../assets/mobile/Planet_B_teaser_en.webp';
import mobileImageZHCN from '../assets/mobile/Planet_B_teaser_zh-cn.webp';
import mobileImageZHTW from '../assets/mobile/Planet_B_teaser_zh-tw.webp';

import recruitingImageKO from '../assets/recruitment/recruitment_ko.webp';
import recruitingImageJA from '../assets/recruitment/recruitment_ja.webp';
import recruitingImageEN from '../assets/recruitment/recruitment_en.webp';
import recruitingImageZHCN from '../assets/recruitment/recruitment_zh-cn.webp';
import recruitingImageZHTW from '../assets/recruitment/recruitment_zh-tw.webp';

import recruitingMobileImageKO from '../assets/recruitment/mobile/recruitment_ko.webp';
import recruitingMobileImageJA from '../assets/recruitment/mobile/recruitment_ja.webp';
import recruitingMobileImageEN from '../assets/recruitment/mobile/recruitment_en.webp';
import recruitingMobileImageZHCN from '../assets/recruitment/mobile/recruitment_zh-cn.webp';
import recruitingMobileImageZHTW from '../assets/recruitment/mobile/recruitment_zh-tw.webp';

import faqButtonToClose from '../assets/recruitment/faq-button/faq_button_to_close.webp';
import faqButtonToOpen from '../assets/recruitment/faq-button/faq_button_to_open.webp';

import faqContentKo from '../assets/recruitment/faq-content/faq_content_ko.webp';
import faqContentJA from '../assets/recruitment/faq-content/faq_content_ja.webp';
import faqContentEN from '../assets/recruitment/faq-content/faq_content_en.webp';
import faqContentZHCN from '../assets/recruitment/faq-content/faq_content_zh-cn.webp';
import faqContentZHTW from '../assets/recruitment/faq-content/faq_content_zh-tw.webp';

import inquiryKO from '../assets/recruitment/inquiry/inquiry_ko.webp';
import inquiryJA from '../assets/recruitment/inquiry/inquiry_ja.webp';
import inquiryEN from '../assets/recruitment/inquiry/inquiry_en.webp';
import inquiryZHCN from '../assets/recruitment/inquiry/inquiry_zh-cn.webp';
import inquiryZHTW from '../assets/recruitment/inquiry/inquiry_zh-tw.webp';

import moveToBoysPlanetKO from '../assets/move-to-boys2planet/image_ko.png';
import moveToBoysPlanetJA from '../assets/move-to-boys2planet/image_ja.png';
import moveToBoysPlanetEN from '../assets/move-to-boys2planet/image_en.png';
import moveToBoysPlanetZHCN from '../assets/move-to-boys2planet/image_zh-cn.png';
import moveToBoysPlanetZHTW from '../assets/move-to-boys2planet/image_zh-tw.png';

export const imageUrls = {
    desktop: {
        ko: imageKO,
        en: imageEN,
        ja: imageJA,
        'zh-cn': imageZHCN,
        'zh-tw': imageZHTW,
    },
    mobile: {
        ko: mobileImageKO,
        en: mobileImageEN,
        ja: mobileImageJA,
        'zh-cn': mobileImageZHCN,
        'zh-tw': mobileImageZHTW,
    },
    recruiting: {
        ko: recruitingImageKO,
        en: recruitingImageEN,
        ja: recruitingImageJA,
        'zh-cn': recruitingImageZHCN,
        'zh-tw': recruitingImageZHTW,
    },
    recruitingMobile: {
        ko: recruitingMobileImageKO,
        en: recruitingMobileImageEN,
        ja: recruitingMobileImageJA,
        'zh-cn': recruitingMobileImageZHCN,
        'zh-tw': recruitingMobileImageZHTW,
    },
    faq: {
        button: { open: faqButtonToOpen, close: faqButtonToClose },
        content: {
            ko: faqContentKo,
            en: faqContentEN,
            ja: faqContentJA,
            'zh-cn': faqContentZHCN,
            'zh-tw': faqContentZHTW,
        },
    },
    inquiry: {
        ko: inquiryKO,
        en: inquiryEN,
        ja: inquiryJA,
        'zh-cn': inquiryZHCN,
        'zh-tw': inquiryZHTW,
    },

    mama: {
        ko: moveToBoysPlanetKO,
        en: moveToBoysPlanetEN,
        ja: moveToBoysPlanetJA,
        'zh-cn': moveToBoysPlanetZHCN,
        'zh-tw': moveToBoysPlanetZHTW,
    },
};
