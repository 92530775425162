import { imageUrls } from './imageUrls';

export function initFAQ(languageCode) {
    const faqButton = document.getElementById('faq-button');
    const faqImageContainer = document.getElementById('faq-image-container');
    const faqImage = document.getElementById('faq-image');
    const helpButton = document.getElementById('help-btn');

    faqButton.src = imageUrls.faq.button.open;
    helpButton.src = imageUrls.inquiry[languageCode];
    faqImageContainer.style.display = 'none';

    let isFAQOpen = false;

    faqButton.addEventListener('click', function () {
        if (!isFAQOpen) {
            faqImage.src = imageUrls.faq.content[languageCode];
            faqImageContainer.style.display = 'flex';
            faqButton.src = imageUrls.faq.button.close;
            faqButton.alt = 'Hide FAQ';

            faqImage.onload = function () {
                const containerRect = faqImageContainer.getBoundingClientRect();
                const scrollTarget =
                    window.pageYOffset +
                    containerRect.top +
                    containerRect.height / 2 -
                    window.innerHeight / 2;
                window.scrollTo({ top: scrollTarget, behavior: 'smooth' });
            };
        } else {
            faqImageContainer.style.display = 'none';
            faqButton.src = imageUrls.faq.button.open;
            faqButton.alt = 'FAQ';
            faqButton.scrollIntoView({ behavior: 'smooth' });
        }
        isFAQOpen = !isFAQOpen;
    });
}
